import React from 'react';
import { Card, Row, Col, Table, Button, Input, message, Divider } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field'
import { formatDate, formatMoney, formatNumber } from '../../component/util';
import Fetch from '../../Fetch';
import FileList from './filelist'
import {
    DownloadOutlined
} from '@ant-design/icons'
import Modal from 'antd/lib/modal/Modal';

const Action = props => {
    return <Button.Group>
        {props.showExport && <Button key="1" type="primary" icon={<DownloadOutlined />} onClick={() => {
            const url = Fetch.getUrl(`/v1/web/sales/${props.sale_id}/fakturpajakcsv?ws_token=${localStorage.getItem('token')}`)
            window.open(url)
        }}>Export CSV Pajak</Button>}
    </Button.Group>
}

const Detail = props => {
    const [value, setValue] = React.useState('')
    const [saving, setSaving] = React.useState(false)
    const [modalShown, setModalShown] = React.useState(false)
    const [{ record, loading }, { refresh }] = useDetail(`/v1/web/sales/${props.match.params.id}`);
    const { sale, items } = record

    React.useEffect(() => {
        if (sale) {
            setValue(sale.tax_faktur_number);
        }
    }, [sale])

    const saveFaktur = async () => {
        setSaving(true)
        try {
            await Fetch.put(`/v1/web/sales/${props.match.params.id}`, { tax_faktur_number: value })
            message.success("Nomor faktur pajak berhasil disimpan")
            setSaving(false)
            setModalShown(false)
            refresh();
        } catch (e) {
            message.error(e)
            setSaving(false)
        }
    }

    const editClicked = () => {
        setModalShown(true);
    }

    return (
        <Card loading={loading} title={<Title title={"Detail penjualan"}
            actions={<Action showExport={sale && sale.tax_faktur_number} sale_id={props.match.params.id} />} />} >
            {!loading &&
                <React.Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField label="Tanggal">{formatDate(sale.date, true)}</TextField>
                            <TextField label="Toko">{sale.branch.fullname}</TextField>
                            <TextField label="Kepada">{sale.partner.fullname}</TextField>
                            <TextField label="Nomor">{sale.number}</TextField>
                            <TextField label="Faktur Pajak">{sale.tax_faktur_number} <a href='#' style={{ marginLeft: 10 }} onClick={editClicked} >Edit</a></TextField>
                            <TextField label="Total">{formatMoney(sale.total)}</TextField>
                        </Col>
                        <Col span={12}>
                            <FileList files={sale.tax_pdf || []} saleId={sale.id} />
                        </Col>
                    </Row>
                    <Divider />
                    <Table dataSource={items} scroll={{ x: true }}>
                        <Table.Column key="product.barcode" dataIndex="product.barcode" title="Barcode" render={(v, r) => r.product.barcode} width={150} />
                        <Table.Column key="product.name" dataIndex="product.name" title="Nama" render={(v, r) => r.product.name} />
                        <Table.Column key="price" dataIndex="price" title="Satuan" render={(v, r) => {
                            const sub = Math.round(r.total / 1.1)
                            return formatNumber(sub / (r.qty / 1000));
                        }} align="right" width={120} />
                        <Table.Column key="qty" dataIndex="qty" title="Qty" render={v => formatMoney(v / 1000)} align="right" width={120} />
                        <Table.Column key="subtotal" dataIndex="price" title="Subtotal" render={(v, r) => {
                            return formatMoney(Math.round(r.total / 1.1));
                        }} align="right" width={120} />
                        <Table.Column key="ppn" dataIndex="price" title="PPN" render={(v, r) => {
                            const s = r.total / 1.1;
                            return formatMoney(Math.round(s / 10));
                        }} align="right" width={120} />
                        <Table.Column key="total" dataIndex="total" title="Total" render={(v, r) => {
                            return formatMoney(v);
                        }} align="right" width={120} />
                    </Table>
                    <Modal visible={modalShown} onCancel={() => setModalShown(false)} title="Edit nomor faktur pajak" okButtonProps={{ onClick: saveFaktur, loading: saving }}>
                        <Input placeholder="Masukkan no faktur pajak" value={value} onChange={v => {
                            setValue(v.target.value)
                        }} />
                    </Modal>
                </React.Fragment>
            }
        </Card >
    );
}

export default Detail;