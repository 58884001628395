import React from 'react';
import Fetch from '../Fetch';

const useDetail = (url, initialState) => {
    const [state, setState] = React.useState({ loading: true, refresh: true, record: {}, ...initialState })

    React.useEffect(() => {
        if (state.refresh) {
            Fetch.get(url).then(v => {
                setState(state => { return { ...state, loading: false, record: v, refresh: false } })
            });
        }
    }, [state.refresh, url]);

    const refresh = () => {
        setState({ ...state, refresh: true, loading: true })
    }

    return [state, {
        refresh: refresh,
    }];
}

export { useDetail }