import React from 'react';
import { Upload, Button, Card, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Fetch from '../../Fetch';
import { indexOf } from 'lodash';

const FileList = props => {
    const fileList = props.files.map(v => {
        return {
            uid: v,
            status: 'done',
            name: v,
            url: Fetch.getUrl('/public/files/' + v)
        }
    })
    const [list, setList] = React.useState(fileList)
    const p = {
        name: 'file',
        action: Fetch.getUrl(`/v1/web/sales/${props.saleId}/file`),
        headers: {
            'Ws-Token': localStorage.getItem('token'),
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        }
    };

    const onChange = (info) => {
        let list = info.fileList
        if (info.file.status === 'removed') {
            message.error('File tidak dapat dihapus')
        }
        if (info.file.status === 'done') {
            message.success(`File sukses diupload`);
            list = info.fileList.map(v => {
                if (v.uid === info.file.uid) {
                    const name = info.file.response.tax_pdf[info.file.response.tax_pdf.length - 1];
                    const url = Fetch.getUrl('/public/files/' + name);
                    return { ...v, name: name, url: url }
                } else
                    return v;

            })
        } else if (info.file.status === 'error') {
            message.error(`File upload error : ${info.file.response.error}`);
            list = info.fileList.filter(v => v.uid !== info.file.uid)
        }
        setList(list)
    }

    return (
        <Card>
            <Upload {...p} onChange={onChange} fileList={list} accept={".pdf"}>
                <Button>
                    <UploadOutlined /> Click to Upload
                </Button>
            </Upload>
        </Card>
    )
}

export default FileList;