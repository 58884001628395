import React, { Component } from 'react';
import './App.less';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import DefaultRoute from './DefaultRoute';
import PageLogin from './page/login';
import PageProfile from './page/profile/profile';
import PageTax from './page/tax/list'
import PageTaxDetail from './page/tax/detail'
import { StoreProvider } from './store/store';
import { Empty } from 'antd';

const CheckLogin = () => {
  const token = localStorage.getItem('token');
  if (token === null || token === "") {
    return <Redirect to="/login" />
  }
  return <Redirect to="/tax" />
}

const NotFound = () => {
  return <div style={{ marginTop: 100 }}>
    <Empty description={"Halaman tidak ditemukan"} />
  </div>
}

class App extends Component {
  render() {
    return (
      <StoreProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={CheckLogin} />
            <Route exact path="/login" component={PageLogin} />
            <DefaultRoute path="/profile" component={PageProfile} />
            <DefaultRoute exact path="/tax" component={PageTax} />
            <DefaultRoute exact path="/tax/:id" component={PageTaxDetail} />
            <DefaultRoute path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
        {/*<LoginModal /> */}
      </StoreProvider>
    );
  }
}

export default App;



