import React, { useState } from 'react';
import { Card, Table, Button, Checkbox, Modal, DatePicker, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { ReloadOutlined, DownloadOutlined } from '@ant-design/icons'
import Fetch from '../../Fetch';
import { formatDate, formatMoney } from '../../component/util'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Checkbox checked={props.checked} style={{ top: '50%', marginRight: 20 }} onChange={props.checkChange}>Hanya yang belum</Checkbox>
            <Button.Group>
                <Button key="1" type="primary" icon={<DownloadOutlined />} onClick={() => {
                    props.exportExcel();
                }}>Excel</Button>
                <Button icon={<ReloadOutlined />} onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [modalExport, setModalExport] = useState({ visible: false, loading: false, start: null, end: null })
    const location = useLocation()
    const params = qs.parse(location.search)
    const [check, setCheck] = React.useState(params.belum === 'true' || params.belum === undefined)
    const [, tb] = useTable(check ? '/v1/web/sales?belum=true' : '/v1/web/sales?belum=false', { filter: { ready: 1 }, order: { key: 'date', order: 'desc', } })

    const checkChange = v => {
        setCheck(v.target.checked)
        tb.reload()
    }

    const openModal = () => {
        setModalExport({ ...modalExport, visible: true })
    }

    const closeModal = () => {
        setModalExport({ ...modalExport, visible: false })
    }

    const dateStartChanged = (v) => {
        setModalExport({ ...modalExport, start: v })
    }

    const dateEndChanged = (v) => {
        setModalExport({ ...modalExport, end: v })
    }

    const exportClicked = () => {
        if (modalExport.start === null || modalExport.end === null) {
            message.error('Tanggal tidak boleh kosong')
            return;
        }
        const url = Fetch.getUrl(`/v1/web/internalsale/export?ws_token=${localStorage.getItem('token')}&date=${modalExport.start.format('YYYY-MM-DD')}&date_end=${modalExport.end.format('YYYY-MM-DD')}`)
        window.open(url)
    }

    return (
        <React.Fragment>
            <Card title={<Title title={"Daftar Penjualan Internal"}
                actions={<Actions  {...props} refresh={tb.reload} checkChange={checkChange} checked={check} exportExcel={openModal} />} />}>
                <Table {...tb.tableProps}>
                    <Table.Column key="date" dataIndex="date" title="Tanggal" width={100} render={formatDate}
                        {...tb.columnProps({ key: 'date', type: 'startend' })} {...tb.sortProps('date')} />
                    <Table.Column key="number" dataIndex="number" title="Nomor" width={180}
                        {...tb.columnProps({ key: 'number', hint: 'Cari nomor' })} />
                    <Table.Column key="tax_faktur_number" dataIndex="tax_faktur_number" title="Nomor Faktur Pajak"
                        {...tb.columnProps({ key: 'sale.tax_faktur_number', hint: 'Cari nomor faktur pajak' })} />
                    <Table.Column key="branch.name" dataIndex="branch.name" title="Toko" width={120} render={(v, r) => {
                        return r.branch.name
                    }} {...tb.columnProps({ key: 'branch_id', hint: 'Pilih toko', type: 'selecturl', dataIndex: 'name', valueIndex: 'id', url: '/v1/branch' })} />
                    <Table.Column key="partner.name" dataIndex="partner.name" title="Kepada" width={120} render={(v, r) => {
                        return r.partner.name
                    }} />
                    <Table.Column key="total" dataIndex="total" title="Total" width={120} align="right" render={formatMoney} />
                    <Table.Column key="purchase.tax_faktur_number" dataIndex="purchase.tax_faktur_number" align="center"
                        title="Faktur Pembelian" width={120} render={(i, r) => {
                            const ready = r.purchase && r.purchase.tax_faktur_number;
                            return <Tag color={ready ? 'green' : 'red'}>{ready ? 'siap' : 'belum'}</Tag>
                        }}
                        {...tb.columnProps({ key: 'ready', hint: 'Ready', type: 'radio', options: [{ value: 1, text: 'siap' }, { value: 0, text: 'belum' }] })} />
                    <Table.Column key="action" dataIndex="id" title="Aksi" width={50} render={(v, r) => <MyLink to={`/tax/${r.id}`}>Detail</MyLink>} />
                </Table>
            </Card >
            <Modal title="Export Excel" visible={modalExport.visible} onCancel={closeModal} onOk={exportClicked}>
                <span>Dari :</span><DatePicker format="DD MMM YYYY" onChange={dateStartChanged} style={{ marginLeft: 10 }} />
                <span style={{ marginLeft: 10 }}>Sampai :</span><DatePicker format="DD MMM YYYY" onChange={dateEndChanged} style={{ marginLeft: 10 }} />
            </Modal>
        </React.Fragment >
    )
}

export default List;