import React from 'react'
import { Card, Form, Input, Button, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import Fetch from '../Fetch';
import { GlobalStore } from '../store/store'

const PageLogin = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [, reducer] = React.useContext(GlobalStore)

    const onFinsih = async (values) => {
        setLoading(true);
        const result = await fetch(Fetch.getUrl('/login'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token-From': 'web',
            },
            body: JSON.stringify({
                username: values.username,
                password: values.password,
            })
        });
        const json = await result.json();
        if (result.status === 200) {
            localStorage.setItem('token', json.access_token);
            localStorage.setItem('user', JSON.stringify(json.user));
            console.log(json)
            reducer({ type: 'UPDATE_USER', payload: json.user })
            //Permission.setPermission(json.user.user_permissions.map(v => v.permission))
            //Fetch.get('/notification/count').then(r => reducer({ type: 'SET_NOTIFICATION', payload: r.count }))
            setTimeout(() => props.history.replace('/'), 500)
        } else {
            message.error('Login failed: ' + json.error);
            setLoading(false);
        }
    }

    return (
        <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: 300, padding: '70px 0' }}>
            <Card title="Login WS Baru">
                <Form onFinish={onFinsih} name="login-form">
                    <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]} >
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]} >
                        <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default PageLogin