import React from 'react';
import { Layout, Menu } from 'antd';
import { MyLink } from '../component/link';
import { GlobalStore } from '../store/store'
import packageJson from '../../package.json'
import { CalculatorFilled } from '@ant-design/icons'
import Permission, {

} from '../permission'

const MySider = (props) => {
    const [{ global }] = React.useContext(GlobalStore);

    const menus = [
        { label: 'Pajak', key: '/tax', path: '/tax', icon: CalculatorFilled, permissions: [] },
    ]

    const buildMenuItem = (arr) => {
        return arr.filter(v => Permission.hasOneOf(v.permissions)).map(menu => {
            if (Array.isArray(menu.children)) {
                return <Menu.SubMenu key={menu.key} icon={<menu.icon />}
                    title={<span className="nav-text">{menu.label}</span>}>
                    {buildMenuItem(menu.children)}
                </Menu.SubMenu>
            }
            return (<Menu.Item key={menu.key} icon={<menu.icon style={{ fontSize: 20 }} />} style={{ paddingLeft: 15 }}>
                <MyLink to={menu.path}>
                    <span className="nav-text">{menu.label}</span>
                </MyLink>
            </Menu.Item>
            )
        })
    }

    const iof = props.location.pathname.indexOf('/', 1);
    const path = iof > 0 ? props.location.pathname.substring(0, iof) : props.location.pathname

    return (
        <div>
            <Layout.Sider style={{
                overflowY: 'auto', height: '100%', backgroundColor: 'white',
            }} width={250} collapsedWidth={50} collapsible collapsed={global.collapse} trigger={null}
            >
                <div>
                    <Menu mode="inline" defaultSelectedKeys={[path]} style={{ padding: 0 }}>
                        {buildMenuItem(menus)}
                    </Menu>
                </div>
                <div style={{ height: 20 }}></div>
            </Layout.Sider>
            <div style={{
                width: global.collapse ? 50 : 250, textAlign: 'center', fontSize: 10, backgroundColor: '#a61d24',
                color: 'white', padding: '5px 0px 5px 0px', position: 'absolute',
                bottom: 0, left: 0
            }}>{packageJson.version}</div>
        </div>
    );
};

export default MySider;